require("rangeslider.js");
require("slick-carousel");
require("jquery-inview");
require("select2")(jQuery);
const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const numbers = [
  {
    value: "1",
    text: "Unico obiettivo",
    image: "1a-obiettivo.jpg",
    color: "#692480",
  },
  {
    value: "6",
    text: "Agenzie collegate",
    image: "6-agenzie.jpg",
    color: "#009de1",
  },
  {
    value: "14",
    text: "Certificazioni",
    image: "14-certificazioni.jpg",
    color: "#15c97f",
  },
  {
    value: "19+",
    text: "Anni di esperienza",
    image: "19-esperienza.jpg",
    color: "#e8512e",
  },
  {
    value: "52",
    text: "Premi Internazionali",
    image: "52-premi.jpg",
    color: "#004b70",
  },
  {
    value: "75",
    text: "Video su YouTube",
    image: "75-youtube.jpg",
    color: "#71c3ba",
  },
  {
    value: "101",
    text: "Professionisti",
    image: "101-professionisti.jpg",
    color: "#692480",
  },
  {
    value: "300",
    text: "Campagne ADV all’attivo",
    image: "300-adv.jpg",
    color: "#009de1",
  },
  {
    value: "650",
    text: "Siti web pubblicati/sviluppati",
    image: "650b-website.jpg",
    color: "#15c97f",
  },
  {
    value: "3.816",
    text: "Ore di formazione",
    image: "3816-formazione.jpg",
    color: "#e8512e",
  },
  {
    value: "5.046",
    text: "Iscritti alle newsletter",
    image: "5046-newsletter.jpg",
    color: "#004b70",
  },
  {
    value: "7.523",
    text: "Slide presentate",
    image: "7523-slide.jpg",
    color: "#71c3ba",
  },
  {
    value: "42.389",
    text: "Task completati",
    image: "42389-task.jpg",
    color: "#692480",
  },
  {
    value: "63.600",
    text: "Ore trascorse in videochiamata",
    image: "63600-videocall.jpg",
    color: "#009de1",
  },
];

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

(function ($) {
  $(function () {
    var preloader = () => {
      var speed = 0.6;
      gsap.to("#nuzo", {
        top: 47,
        left: 50,
        width: "9.7vw",
        xPercent: 0,
        yPercent: 0,
        duration: speed,
      });
      gsap.to("#tagline", {
        top: 80,
        yPercent: 0,
        marginLeft: 0,
        marginTop: 0,
        width: 280,
        fontSize: 14,
        textAlign: "center",
        duration: speed,
      });
      gsap.to("#gradient-bar", { opacity: 0, duration: speed });
      gsap.to("#top-gradient-bar", { opacity: 1, duration: speed });
      gsap.to("#menu", { opacity: 1, duration: speed });
      gsap.to(".preloader__bg", {
        opacity: 0,
        duration: speed,
        onComplete: function () {
          this._targets[0].style.display = "none";
        },
      });
    };
    var preloaderMobile = () => {
      var speed = 0.6;
      gsap.to("#nuzo", { opacity: 0, duration: speed });
      gsap.to("#tagline", { opacity: 0, duration: speed });
      gsap.to("#gradient-bar", { opacity: 0, duration: speed });
      gsap.to("#top-gradient-bar", { opacity: 1, duration: speed });
      gsap.to("#nuzo-mobile", { opacity: 1, duration: speed });
      gsap.to("#mobile-menu-toggle", { opacity: 1, duration: speed });
      gsap.to(".preloader__bg", {
        opacity: 0,
        duration: speed,
        onComplete: function () {
          this._targets[0].style.display = "none";
        },
      });
    };

    var topRectangles = () => {
      var speed = 0.4;
      var tl = gsap.timeline().pause();
      tl.to(
        ".intro__rectangles svg rect:not(#Rettangolo_34)",
        { x: 457, y: 218, duration: speed },
        0
      );
      tl.to(
        ".intro__rectangles svg rect#Rettangolo_34",
        { width: 182, height: 37, x: 310, duration: speed },
        0
      );
      $(window).on("scroll", function (event) {
        var scroll = $(window).scrollTop();
        if (scroll > 0) {
          tl.play();
        } else {
          tl.reverse();
        }
      });
    };

    var bottomRectangles = () => {
      if ($(".rectangles-sep").length > 0) {
        var speed = 0.4;
        var tl = gsap.timeline().pause();
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_33",
          { x: 230, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_32",
          { x: 380, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_37",
          { x: 280, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_36",
          { x: 480, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_35",
          { x: 680, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_38",
          { x: 430, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_40",
          { x: 530, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_41",
          { x: 280, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_43",
          { x: 380, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_45",
          { x: 680, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_46",
          { x: 340, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_48",
          { x: 580, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_49",
          { x: 430, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_50",
          { x: 980, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_51",
          { x: 810, duration: speed },
          0
        );
        tl.to(
          ".rectangles-sep svg rect#Rettangolo_52",
          { x: 630, duration: speed },
          0
        );

        $(".rectangles-sep").on("inview", function (event, isInView) {
          if (isInView) {
            setTimeout(function () {
              tl.play();
            }, 200);
          } else {
            tl.reverse();
          }
        });
      }
    };

    var menuToggleMobile = () => {
      var toggle = $("#mobile-menu-toggle");
      var menu = $("#menu");
      toggle.on("click", function () {
        menu.toggleClass("mobile-active");
        toggle.toggleClass("mobile-active");
        $("body").toggleClass("no-scroll");
      });
    };

    var numbersSlider = () => {
      // Populate data
      //background-image: url(public/img/counter/${el.image});
      numbers.forEach(function (el) {
        $(".numbers-carousel").append(`<div class="item">
                                            <div class="item-content">
                                            <div class="container">
                                                <div class="row">
                                                <div class="col-12 col-md-12">
                                                    <div
                                                    class="big-no fs${el.value.length}"
                                                    style="
                                                        color: ${el.color}
                                                    "
                                                    >
                                                    ${el.value}
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                <div class="col-12 col-md-8 col-md-offset-2">
                                                    <p>
                                                    ${el.text}
                                                    </p>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>`);
        $(".numbers-pager-container").append('<div class="page"></div>');
      });

      const slider = $(".numbers-carousel");

      slider.on("init", function (slick) {
        $(".numbers-pager .page").eq(0).addClass("active");
      });

      var speed = 300;

      slider.slick({
        dots: false,
        // vertical: true,
        arrows: true,
        fade: true,
        prevArrow: $("#prev"),
        nextArrow: $("#next"),
        autoplay: false,
        autoplaySpeed: 2000,
        speed: speed,
        //pauseOnHover: true
      });

      function onWheel(e) {
        if (e.originalEvent.deltaY > 0) {
          slider.slick("slickNext");
        } else {
          slider.slick("slickPrev");
        }
      }

      function onTouchMove(e) {
        var currentY = e.originalEvent.touches[0].clientY;
        console.log(currentY + " ? " + lastY);
        if (currentY > lastY) {
          slider.slick("slickPrev");
        } else if (currentY < lastY) {
          slider.slick("slickNext");
        }
        lastY = currentY;
      }

      // slider.on('wheel', function (e) {
      //         onWheel(e);
      // });

      // WHEEL
      var wheeldelta = {
        x: 0,
        y: 0,
      };
      var wheeling;
      var ts;
      // console.log(window.mobileAndTabletCheck())
      // if (window.mobileAndTabletCheck() == false) {
      slider.on("wheel", function (e) {
        if (!wheeling) {
          // console.log('start wheeling!');
          onWheel(e);
        }

        clearTimeout(wheeling);
        wheeling = setTimeout(function () {
          // console.log('stop wheeling!');
          wheeling = undefined;

          // reset wheeldelta
          wheeldelta.x = 0;
          wheeldelta.y = 0;
        }, 50);

        wheeldelta.x += e.deltaFactor * e.deltaX;
        wheeldelta.y += e.deltaFactor * e.deltaY;
        //console.log(wheeldelta);
      });
      // } else {
      //var lastY;
      // slider.on('touchmove', function (e) {
      //     onTouchMove(e);
      // });

      slider.on("touchstart", function (e) {
        ts = e.originalEvent.touches[0].clientY;
      });

      slider.on("touchmove", function (e) {
        var te = e.originalEvent.changedTouches[0].clientY;
        console.log(ts + " ? " + te);
        if (ts > te) {
          slider.slick("slickNext");
        } else {
          slider.slick("slickPrev");
        }
      });
      // }

      document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.key == "ArrowDown") {
          slider.slick("slickNext");
        }
        if (evt.key == "ArrowUp") {
          slider.slick("slickPrev");
        }
      };

      slider.on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          $(".numbers-pager .page").removeClass("active");
          $(".numbers-pager .page").eq(nextSlide).addClass("active");
          // slider.off('wheel');
        }
      );
      // slider.on('afterChange', function(event, slick, currentSlide){
      //     slider.on('wheel', function (e) {
      //         onWheel(e);
      //     });
      // });

      $(".numbers-pager .page").on("click", function () {
        var target = $(this).index();
        slider.slick("slickGoTo", target);
      });
    };

    var isHome = $("body").hasClass("homepage");
    var isNumbers = $("body").hasClass("numbers");

    if (isHome && $(window).width() < 1024) {
      setTimeout(preloaderMobile, 700);
      topRectangles();
    }
    if (isHome && $(window).width() >= 1024) {
      setTimeout(preloader, 700);
      topRectangles();
    }
    if (isNumbers) {
      numbersSlider();
      disableBodyScroll(document.body);
    }

    // INIT
    menuToggleMobile();
    bottomRectangles();

    //HEADER BG
    let didScroll = false;

    window.onscroll = () => {
      didScroll = true;
      $(".scrolled").removeClass("active");
    };

    setInterval(() => {
      if (didScroll) {
        didScroll = false;
        if (!$(".scrolled").hasClass("active") && window.pageYOffset) {
          $(".scrolled").addClass("active");
        }
      }
    }, 250);

    $(".intro__dm").on("click", function (event) {
      event.preventDefault();
      $("html,body").animate(
        {
          scrollTop: $("#what").offset().top,
        },
        "slow"
      );
    });

    $(".ready-cta").on("click", function (event) {
      event.preventDefault();
      $("html,body").animate(
        {
          scrollTop: $("#kit").offset().top,
        },
        "slow"
      );
    });

    $(".kit form input, .kit form textarea").on("click", function () {
      var form = $(".kit form");
      form.find("#output").hide();
    });

    $(".kit form").on("submit", function (event) {
      event.preventDefault();
      var form = $(".kit form");
      var postData = {
        name: form.find("#name").val(),
        email: form.find("#email").val(),
        message: form.find("#message").val(),
        privacy: form.find("#privacy").val(),
      };
      $.ajax({
        url: "sendmail.php",
        type: "POST",
        data: postData,
        success: function (data) {
          console.log(data);
          if (data == 1) {
            // form.find("#name").val("");
            // form.find("#email").val("");
            // form.find("#message").val("");
            // form.find("#privacy").prop("checked", false);
            // form.find("#output").show();
            window.location.replace("thankyou.php");
          }
        },
        error: function (xhr, status, error) {
          console.log(error);
        },
      });
    });
  });
})(jQuery);
